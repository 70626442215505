import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteDiaryItem, SiteDiaryService} from '../../../services/SiteDiary.service';
declare let laydate:any

@Component({
  selector: 'SiteDiary-Details-Pupop',
  templateUrl: './SiteDiary-Details-Pupop.component.html',
  styleUrls: ['./SiteDiary-Details-Pupop.component.sass']
})
export class SiteDiaryDetailsPupopComponent implements OnInit {

  @Input() public pupopType:any
  @Input() public summaryData:SiteDiaryItem

  @Output() close = new EventEmitter();
  public ownershipDialog:any = false
  public htmlModel:any

  public title:any = ''
  public select:any = []
  public copyCheckAll:boolean = false
  public EmptyTip:any = {
    ownershipTip: false,
    numberTip: false
  }
  public addStaffsData:any = {
    staff_id:'',
    number:'',
    ownership:'CW-FWS JV',
  }
  public tableList = []

  public updataData = null
  public ownershipList:any = []
  public ownerList:any= []
  public dateNow:any = ''
  public copyDialog:boolean = false
  public copyList:any = []
  public site_id = location.search.split('?')[1].split('&')[1].split('=')[1]
  public Copylaydate:any
  constructor(
    public service: SiteDiaryService,
  ) { }

  ngOnInit() {
    this.title = this.pupopType == 'site_staffs' ? 'Site Staffs' : 'Assistance'
    let ownerType = this.pupopType == 'site_staffs' ? 'SiteStaff' : 'SiteAssistance'
    this.service.getOwnerList(ownerType).subscribe( (res:any) => {
      console.log(res)
      this.ownershipList = res.data
      this.ownerList = res.data
    })
    
    this.service.getSummaryStaffs({staff_type:this.pupopType == 'site_staffs' ? 'site_staffs' : 'assistances'}).subscribe((res:any)=>{
      this.select = res.sort( (pre:any,next:any) => {
        if( pre.name > next.name ) {
          return 1
        } else {
          return -1
        }
       })
      console.log(res)
    })
    this.getList()
    this.getNowDate()
  }
  getNowDate(){
    let time =new Date()
    let y =time.getFullYear()
    let m:any = time.getMonth()+1
    let d:any = time.getDate()
    d=d<10?`0${d}`:d
    m=m<10?`0${m}`:m
    this.dateNow =`${y}-${m}-${d}`
    this.service.getOwnerCopyList(this.dateNow,this.site_id).subscribe( (res:any) => {
      console.log(res)
      this.copyList = res.list
    })
  }
  checkAll() {
    this.copyList.forEach( (item:any) => {
        item.copy = !this.copyCheckAll
    })
  }
  copySubmit() {
    let copyarr = this.copyList.filter( (item:any) => {
      return item.copy
    }).map( (item:any) => {
      return item.site_staff_id
    })
    console.log(copyarr)
    this.service.copyOwner(this.site_id,copyarr).subscribe( () => {
      this.getList()
      this. copyDialog = false
    })
  }
  imgdata(name:any){
    
    return `${window.location.origin}/images/dashboard/${name}`
  }

  closeCopy() {

  }
  copyOwnership() {

  }
  dateNowChange() {
    laydate.render({
      elem: '#dateNow',
      type:'date',
      value:this.dateNow,
      show:true,
      lang: 'en',
      done:(value:any)=>{
        console.log(value)
        this.dateNow = value
        this.service.getOwnerCopyList(this.dateNow,this.site_id).subscribe( (res:any) => {
          console.log(res)
          this.copyList = res.list
        })
      }
    });
  }
  //获取列表数据
  getList(){
    let data = {
      site_diary_id:this.summaryData.id,
      staff_item_type:this.pupopType == 'site_staffs' ? 'SiteStaff' : 'Assistance',
      page:1,
      per_page:999
    }
    this.service.getStaffsAndAssistanceList(data).subscribe((res:any)=>{
      this.tableList = res
    })
  }
  openCopy() {

  }
  inputChange(event:any,type:any) {
    if(type=='ownership') {
      if(!event) {
        this.ownershipList = this.ownerList
        return
      }
      let list = this.ownerList.filter((res:any)=>{return res.ownership_name.toLowerCase().indexOf(event) == 0})
      this.ownershipList = list.length == 0 ? event ? list : this.ownerList : list
    }
  }
  //添加列表数据
  add(){
    for(let key in this.EmptyTip) {
      this.EmptyTip[key] =  false
    }
    let returnBoolean = false
    if(!this.addStaffsData.ownership){
      this.EmptyTip.ownershipTip = true
      returnBoolean = true
    }
    if(Number(this.addStaffsData.number)<0){
      this.EmptyTip.numberTip = true
      returnBoolean = true
    }
    if(returnBoolean) {
      return
    }
    this.addStaffsData['site_diary_id'] = this.summaryData.id
    this.addStaffsData['staff_item_type'] = this.pupopType == 'site_staffs' ? 'SiteStaff' : 'Assistance'
    this.service.addStaffsAndAssistance(this.addStaffsData).subscribe((res:any)=>{
      if(this.pupopType == 'site_staffs') this.summaryData.site_staff += this.addStaffsData.number
      if(this.pupopType == 'assistances') this.summaryData.assistance += this.addStaffsData.number
      this.getList()
      this.addStaffsData = {
        staff_id:'',
        number:'',
        ownership:'CW-FWS JV',
      }
    },
    (error:any)=>{
      alert(error.error.error);
    })
  }

  //删除列表数据
  deleteClick(item:any){
    if(this.updataData) return
    this.service.deleteStaffsAndAssistanceList({site_diary_id:this.summaryData.id,staff_item_id:item.id}).subscribe((res)=>{
      if(this.pupopType == 'site_staffs') this.summaryData.site_staff -= item.number
      if(this.pupopType == 'assistances') this.summaryData.assistance -= item.number
      this.getList()
    })  
  }

  //修改列表按钮
  editClick(item:any){
    if(this.updataData) return
    this.updataData = item
    this.addStaffsData = item
  }

  //修改确定按钮
  updataClick(){
    let data = {
      site_diary_id:this.summaryData.id,
      staff_item_id:this.addStaffsData.id,
      staff_id:this.addStaffsData.staff_id,
      number:this.addStaffsData.number,
      ownership:this.addStaffsData.ownership
    }
    this.service.updateStaffsAndAssistanceList(data).subscribe((res)=>{
      this.addStaffsData={
        staff_id:'',
        number:'',
        ownership:'CW-FWS JV',
      }
      this.updataData=null
      this.getList()
    })
  }


  

  //ok按钮
  okclick(){
    let numberData = 0
    this.tableList.forEach((res:any,index:any) => {
      console.log(res.number,index)
      numberData+=res.number
      if(this.tableList.length-1 == index){
        if(this.pupopType == 'site_staffs') this.summaryData.site_staff = numberData
        if(this.pupopType == 'assistances') this.summaryData.assistance = numberData
      }
    });
    jQuery('#top-menu').css('z-index','22')
    this.close.emit()
  }

}
