import { data } from 'jquery';
/*
 * @Author: your name
 * @Date: 2020-06-18 10:38:47
 * @LastEditTime: 2020-06-30 16:14:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-newtemplate-ctitem/check_lists-newtemplate-ctitem.component.ts
 */ 
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { SortablejsOptions } from 'ngx-sortablejs';
import { arrayTuples } from '@uirouter/core';

@Component({
  selector: 'check_lists-newtemplate-ctitem',
  templateUrl: './check_lists-newtemplate-ctitem.component.html',
  styleUrls: ['./check_lists-newtemplate-ctitem.component.sass']
})


export class Check_listsNewtemplateCtitemComponent implements OnInit {

  
  public admin = [ 1]

  constructor(
    public i18n: I18nService,
    protected cdRef:ChangeDetectorRef
  ) { }
  @Input() public dataItems:any
  @Input() public indexs:any
  @Input() public i:any
  @Input() public kind_id:any
  @Output() deleteOne = new EventEmitter();
  @Output() update = new EventEmitter();

  public showUl:any = false
  public uiIndex:any
  public fathershowUl:boolean = false
  public checkboxValue:any
  public necessary:boolean = true
  public addTypeValue:any='text'
  public numberKind_id:any = ['6','8','9','10']
  optionsadmin: SortablejsOptions = {
    group: 'name',
    handle:'.itemBimQualityApp',
    draggable:'.draggabli',
    onUpdate: (arr:any) => {
      console.log(arr);
      console.log(this.dataItems)
      
    }
  };

  deleteArr(arr:any,i:any){
    arr.splice(i,1)
    this.update.emit()
  }
  addAnswer(index:any){
    this.dataItems[index].options.push('')
    this.update.emit()
  }
  
  updatedType(e:any,index:number){
 if (e === 'radio') {
      this.dataItems[index].options = [
        this.i18n.t("js.check-lists.template.templateyes"),
        this.i18n.t("js.check-lists.template.templateno"),
        this.i18n.t("js.check-lists.template.nothing")
      ]
    }else if (e == 'checkbox'||e=='select'){
      this.dataItems[index].options = ['']
    }else if(e == 'Int' || e == 'Number') {
      this.dataItems[index].value = 0
    }else{
      this.dataItems[index].options = null
    }
    this.update.emit()

  }

  deleteDate(){
    this.deleteOne.emit({
      indexs:this.indexs,
      i:this.i
    })
  }
  toDelete(index:any){
    this.dataItems.splice(index,1)
  }

  updateCheckboxValue(e:any,i:any,index:any){
    
    this.dataItems[index].options[i] = e.target.value
    this.update.emit()
    this.cdRef.detectChanges();
  }

  trackByFn(index: any, item: any) {
    return index;
   } 

   select(event:any){
    event.target.select();
   }

   inputBlur(index:any){
    if(!this.dataItems[index].title){
      this.dataItems[index].title = this.i18n.t("js.check-lists.template.notitleitem")
    }
  }

  addArr(a:any){
    console.log(a)
    this.dataItems.options.push('')
    
  }

  changeSelect(index:any){
    this.dataItems[index].necessary = !this.dataItems[index].necessary
  }

  toShowUl(index:any){
    this.showUl = !this.showUl
    this.uiIndex = index
  }


  addSonItem(){
    let newOptions:any = []
    let value= '' as any
   if (this.addTypeValue === 'radio') {
    newOptions = [
        this.i18n.t("js.check-lists.template.templateyes"),
        this.i18n.t("js.check-lists.template.templateno"),
        this.i18n.t("js.check-lists.template.nothing")
      ]
    }else if (this.addTypeValue=== 'checkbox'||this.addTypeValue=='select'){
      newOptions = ['']
    } else if(this.addTypeValue == 'Int' || this.addTypeValue == 'Number') {
      value = 0
    }else{
      newOptions = null
    }

    this.dataItems.push({
        "type":this.addTypeValue,
        "title":this.i18n.t("js.check-lists.template.newentry"),
        "value":value,
        "options":newOptions,
        "remark":null,
        "default":null,
        "issue_id":null,
        "description":"",
        "image":null ,
        "necessary":true
    })

  }
  

  ngOnInit() {
  for(const item of this.dataItems){
    if(!item.hasOwnProperty('necessary')){
      item.necessary =true
    }
    
    // item.showDescription=false
  }
  }

}
