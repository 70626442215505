import {Component, Inject} from "@angular/core";
import {
  OpContextMenuItem,
  OpContextMenuLocalsMap, OpContextMenuLocalsToken
} from "core-components/op-context-menu/op-context-menu.types";
import {OPContextMenuService} from "core-components/op-context-menu/op-context-menu.service";

@Component({
  templateUrl: './op-context-menu.html'
})
export class OPContextMenuComponent {
  public items:OpContextMenuItem[] ;
  public service:OPContextMenuService;

  constructor(@Inject(OpContextMenuLocalsToken) public locals:OpContextMenuLocalsMap) {
    this.items = this.locals.items;
    this.service = this.locals.service;
    console.log(this.service.wpType);
    console.log(this.items);
    console.log('this.service',this.service);
    let elementMe:any
    if(document.body.querySelector('#menu-sidebar') == null){
      elementMe = document.createElement('div') as any
    } else {
        if (this.locals && this.locals.workPackage && this.locals.workPackage.type && this.locals.workPackage.type.$source) {
          const id = this.locals.workPackage.type.$source.id;
          elementMe = {
            innerText: id === 18 ? 'RISC(Work)' : (id === 15 ? 'RISC(Survey)' : '')
          }
        } else {
             elementMe = document.body.querySelector('#menu-sidebar')!.querySelector('.selected')!.querySelector('.menu-item--title') as any
          }
        }
    let innerText = elementMe.innerText
    let filter = this.filterList(innerText)
    filter('RISC(Survey)', 'RISC')
    filter('RISC(Work)', 'RISC(inspection)')
    console.log(this.items[0].hasOwnProperty('icon'))
    if((this.service.wpType == 'RISC' || this.service.wpType == 'RISC(inspection)') && this.items[0].hasOwnProperty('icon')){

      let data:any = this.items.find((res:any)=>{
        return res.linkText == "Resubmit"
      })
      let dataOther:any = this.items.find((res:any)=>{
        return res.linkText == 'Cancel' || res.linkText == '取消'
      })
      if(this.service.status == 'Closed with disapproval') {

        this.items = [data]
      } else {
        this.items = [dataOther]
      }
      console.log(this.items)
    }
  }


  filterList(innerText:String) {
    return (text:String, filter:String) => {

      if(innerText === text) {
        let items = this.items.filter( (item:any) => {
          return item.linkText === filter
        })
        if(items.length > 0) {
          this.items = items
        }
      }
    }
  }
  public handleClick(item:OpContextMenuItem, $event:JQuery.TriggeredEvent) {
    if (item.disabled || item.divider) {
      return false;
    }

    if (item.onClick!($event)) {
      this.locals.service.close();
      $event.preventDefault();
      $event.stopPropagation();
      return false;
    }

    return true;
  }
}
