import { Component, OnInit, ChangeDetectorRef ,Output} from '@angular/core';
import { DocumentsService } from './services/check.lists.service';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { EventEmitter } from 'events';
import { data } from 'jquery';
import { HttpClient } from "@angular/common/http";
import {ExpressionService} from "../../../../common/expression.service";



declare let layui: any
@Component({
  templateUrl: './check_lists.component.html',
  styleUrls: ['./check_lists.component.sass']
})
export class Check_listsComponent implements OnInit {

public saveObj:any = {}
public showChecklist:string = 'formlist'     //列表显示标识符
public changeMoudulelist:any    //更改模板list
public modulesize:any = []   //module页码
public modulelist:any
public modulepage=1

  public buttonnewdips = false
  public csrf_token:any
  public newHeaders:any
  public newtemlanlist:any
  private projectId:any
  public displayquest = false
  public displaylist:any = false
  public check_listsID:any
  public checklistsindexitem:any
  public showNewtemplate:any = false
  public dataObject:any={}
  public showNext:any=false
  public yesIssue:any = false
  public updatetemplate:any = ''

  public page = 1
  public per = 15
  public perlist:any=[]
  public perlength:any
  public nav_pos:any = false
  public changepos:any
  public showFinish:any = false
  public showTemList:any = false
  public isUserAdmin:boolean = false
  public template:any
  public kind_id:any
  public create_power:any
  public unique_date_sort:any
  public action:string = 'All'
  constructor(
    protected cdRef:ChangeDetectorRef,    
    public service: DocumentsService,
    public i18n: I18nService,
    readonly http: HttpClient,
    ) { 
    
  }

  ngOnInit() {
    jQuery('#content').css({'height':'100%'});
    this.projectId = document.querySelector('meta[name=current_project]')
    this.csrf_token = jQuery("meta[name=csrf-token]").attr("content");
    let newHeaders: any = {};
    newHeaders["X-Authentication-Scheme"] = "Session";
    newHeaders["X-Requested-With"] = "XMLHttpRequest"
    if (this.csrf_token) {
      newHeaders["X-CSRF-TOKEN"] = this.csrf_token;
    }
    this.newHeaders = newHeaders

    //获取携带过来的kind-id
    this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]

    this.checklistsindex(this.kind_id)
    this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
      this.newtemlanlist = res
      this.modulelist = res.slice(0,15)
       this.modulesize =  []
      for(let i =0;i<Math.ceil(res.length/this.per);i++){
        this.modulesize.push("i")
      }
    })

    // this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
    //   this.newtemlanlist = res
    //   this.modulelist = res.slice(0,15)
    //    this.modulesize =  
    //   for(let i =0;i<Math.ceil(res.length/this.per);i++){
    //     this.modulesize.push("i")
    //   }
    // })

    if(window.location.href.indexOf('check_listsID')>=0){
      const data =  location.search.split('&')[1].split('=')[1]
      this.check_listsID = data
      this.dipschecklist()
    }else{
      this.displaylist = true
    }

    const currentUser: any = document.querySelector('meta[name=current_user]');
    if(!currentUser) return
    this.service.getUserList(this.projectId.dataset.projectId,  null).subscribe((res: any) => {
      res._embedded.elements.forEach((resitem:any)=>{
        if(resitem.id == currentUser.dataset.id){
          if(resitem.admin) {
            this.isUserAdmin = true
            sessionStorage.setItem('isUserAdmin','true')
          }else{
            sessionStorage.setItem('isUserAdmin','false')
          }
        }
      })
    })
  }

  createEvent(e:any) {
    if(e.type == 'create') {
      this.check_listsID = e.check_listsID
      this.dipschecklist()
      
    }
    this.template = null
  }
  TemList(){
    this.showTemList  = !this.showTemList
  }

  dianjishijian(){
    this.buttonnewdips = true
    this.displaylist = false
    this.displayquest  = false
    this.nav_pos = false
  }

  newtemitemclick(e:any){
    this.template = e
    // console.log(e);
    this.showTemList = false
    // let data = {
    //   template_id:e.id,
    //   name:e.name,
    //   description:'Environmental - Waste Water Inspection',
    //   check_list_kind_id : Number(this.kind_id)
    // }
    // this.service.newcheckLists(this.projectId.dataset.projectId,data).subscribe((res: any) => {
    //   this.check_listsID = res.id
    //   this.dipschecklist()
    //   console.log(res)
      
    // })
    
  }

  closeTep(){
    this.buttonnewdips = false
    this.showNewtemplate = false
    this.displaylist = true
    this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
      this.newtemlanlist = res
      this.modulelist = res.slice(0,15)
    })
  }

  getDataObject(e:any){
    console.log(e)
    this.dataObject = e
    //回退保存
    this.saveObj = e
    this.showNext = this.dataObject.name && this.dataObject.check_list_type_id
  }

  finish(e:any){
    console.log(e)
    this.showFinish = e
  }

  nextStep(){
    this.showNewtemplate = true
    this.buttonnewdips = false
    this.yesIssue = false
    this.changepos = true
  }

  backStep(e:any){
    this.changepos = false
    this.showNewtemplate = false
    this.buttonnewdips = true
  }



  showLists(){
    this.buttonnewdips = false
    this.showNewtemplate = false
    this.displaylist = true
    this.saveObj = {}
    if(!this.changeMoudulelist) return
    this.changeMoudulelist=null
    this.updatetemplate = null

  }


  getIssue(){
    this.saveObj = {}
    this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
      console.log(res)
      this.newtemlanlist = res
    })
    console.log(this.changeMoudulelist);
    
    if(this.changeMoudulelist) this.updatetemplate = this.changeMoudulelist.id
    this.yesIssue = true
  }

  close(){
    
    this.checklistsindex(this.kind_id)
    this.buttonnewdips = false
    this.displayquest = false
    this.displaylist = true
    
    this.cdRef.detectChanges();

    this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
      console.log(res)
      this.newtemlanlist = res
    })
    jQuery(".titleclss").css('display','block')
    
  }

  perclick(index:any){
    this.page = index
    this.checklistsindex(this.kind_id)
  }

  perclicklink(bloack:any){
    if(bloack == 'prev'){
      this.page = this.page-1
    }else{
      this.page = this.page+1
    }
    this.checklistsindex(this.kind_id)
  }

  changeSort(e:any) {
    this.unique_date_sort = e
    this.checklistsindex(this.kind_id)
  }

  checklistsindex(e:any){
    const data = {page:this.page,per:this.per,kind_id:e,by_action: this.action}
    let  unique_date_sort = 'desc'
    if(e == 6) {
      unique_date_sort = this.unique_date_sort
    }
    this.service.checklistsindex(this.projectId.dataset.projectId,data,unique_date_sort).subscribe((res:any)=>{
      res.data.forEach( (item:any) => {
        switch(item.state) {
          case 'pending' : item.state = this.i18n.t('js.check-lists.quest.Notstarted'); break;
          case 'processing' : item.state = this.i18n.t('js.check-lists.quest.Inprogress'); break;
          case 'finished' : item.state = this.i18n.t('js.check-lists.quest.Completed'); break;
        }
      })
      this.checklistsindexitem = res.data
      console.log( this.checklistsindexitem,'djjddjkm,kl');
      
      this.create_power = res.create_power
      sessionStorage.setItem('is_consultant',res.is_consultant)
      let list = parseInt(((res.total_count+data.per-1)/data.per).toString().split('.')[0])
      this.perlist = []
      for(let i = 0 ; i < list;i++){
        this.perlist.push(i)
      } 
      this.perlength = this.perlist.length
    })
  }

  clicklistid(event:any){
    if(event=='copy'){
      this.checklistsindex(this.kind_id)
    }else{
      this.check_listsID = event
      this.dipschecklist()
    }
    
  }
  

  dipschecklist(){
    this.buttonnewdips =false
    this.displayquest = true
    this.displaylist = false
    jQuery(".titleclss").css('display','none')
  }

  show_checklist(e:string){
    console.log(e)
    this.showChecklist = e
    
  }

  //删除模板
  moduleListid(e:any){
    console.log(e)
    this.service.deleteModulelist(this.projectId.dataset.projectId,e).subscribe((res:any)=>{
      this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
        this.modulelist = res.slice(0,15)
        this.modulepage = 1

        
      })

    }
    )
  }
  
  //编辑
  editmoduleListid(e:any){
  console.log(e)
  this.buttonnewdips = true
    this.displaylist = false
    this.displayquest  = false
    this.nav_pos = false
  this.changeMoudulelist = e  
}

//modulelist分页
modulelistpage(i:any){
  this.modulepage = i
this.service.getnewtemplate(this.projectId.dataset.projectId,this.kind_id).subscribe((res: any) => {
  this.modulelist = res.slice((i-1)*15,i*15)
})
}


modulego(bloack:any){
  if(bloack == 'prev'){
    this.modulepage = this.modulepage-1
    this.modulelistpage(this.modulepage)
  }else{
    this.modulepage = this.modulepage+1
    this.modulelistpage(this.modulepage)
  }
}
//打开下载弹框
openDownLoadDialog(){
  let self = this
  layui.use(['jquery','layer','laydate', 'dropdown'], function() {
    let startTime = '',endTime='',state='';
    var layer = layui.layer, $ = layui.$,
    laydate =  layui.laydate,dropdown = layui.dropdown;
    let date1 = new Date().toLocaleDateString().replace(/\//g,'-');
    let data2 = ExpressionService.lastMonth()
    layer.open({
      content:'<div class="layui-form"><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;">Period</label><div class="layui-inline" id="test6"><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-startDate-1" class="layui-input" placeholder="Form"></div><div class="layui-form-mid">-</div><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-endDate-1" class="layui-input" placeholder="To"></div> </div></div></div><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;"></label><button class="layui-btn" id="demo1">Status<i class="layui-icon layui-icon-down layui-font-12"></i></button></div></div></div>',
      // area: ['400px', '400px'],
      title:'Export',
      btn:['Confirm'],
      yes: function(index:any, layero:any){
        //do something
        window.open(`/projects/${self.projectId.dataset.projectId}/check_lists/export_excel?from=${startTime}&to=${endTime}&state=${state}&check_list_kind_id=${self.kind_id}`)
        layer.close(index); //如果设定了yes回调，需进行手工关闭
      }
    });
    laydate.render({
      elem: '#test6',
      range: '-'
      ,format: 'yyyy-MM-dd',
       value: `${data2} - ${date1}`
      ,isInitValue: true
      ,lang: 'en'
      ,done: function(value:any, date:any, endDate:any){
        // console.log(this.elem);
        console.log(date,endDate); //得到日期生成的值，如：2017-08-18
        startTime = date.year+'-'+date.month+'-'+date.date
        endTime = endDate.year+'-'+endDate.month+'-'+endDate.date
        $('#test-startDate-1').val(startTime)
        $('#test-endDate-1').val(endTime)
      }
    });
    console.log(self.projectId);
    self.http.get(`/api/v3/projects/${self.projectId.dataset.projectId}/check_lists/check_list_status?check_list_kind_id=${self.kind_id}`).toPromise().then((res:any) => {
   let arr:any = []
    Object.keys(res).forEach((key) => {
      arr.push({title:key,id:res[key]})
     })
     dropdown.render({
      elem: '#demo1' //可绑定在任意元素中，此处以上述按钮为例
      ,data: arr
      ,id: 'demo1'
      //菜单被点击的事件
      ,click: function(obj:any){
          state =obj.id
          this.elem.html(`${obj.title}<i class="layui-icon layui-icon-down layui-font-12"></i>`)
      }
    });
    })

  
})

}

getList(action:string){
  this.page = 1
  this.action = action
  this.checklistsindex(this.kind_id)
}

}
