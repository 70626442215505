import {WorkPackageResource} from 'core-app/modules/hal/resources/work-package-resource';
import {
  DisplayFieldRenderer,
  editFieldContainerClass
} from '../../wp-edit-form/display-field-renderer';
import {Injector} from '@angular/core';
import {QueryColumn} from "core-components/wp-query/query-column";
export const tdClassName = 'wp-table--cell-td';
export const editCellContainer = 'wp-table--cell-container';
import {WorkPackagesListService} from "core-components/wp-list/wp-list.service";
declare let layui: any

export class CellBuilder {

  public fieldRenderer = new DisplayFieldRenderer(this.injector, 'table');
  readonly listServe:WorkPackagesListService = this.injector.get(WorkPackagesListService);
  public lateSubmission:boolean = false
  constructor(public injector:Injector,
              ) {
  }

  public build(workPackage:WorkPackageResource, column:QueryColumn) {
    const td = document.createElement('td');
    const attribute = column.id;
    td.classList.add(tdClassName, attribute);
    td.style.whiteSpace = 'break-spaces'

    if (attribute === 'subject') {
      td.classList.add('-max');
    }
    const schema = workPackage.schema[attribute];
    if (schema && schema.type === 'User') {
      td.classList.add('-contains-avatar');
    }
    const container = document.createElement('span');
    container.classList.add(editCellContainer, editFieldContainerClass, attribute);
    const displayElement = this.fieldRenderer.render(workPackage, attribute, null);
    container.style.whiteSpace = 'break-spaces'
    container.style.wordBreak = 'break-all';
    container.appendChild(displayElement);
    if(attribute == 'id') {
      container.style.display = 'inline-block'
      container.style.width = '68%'
      console.log(workPackage)

      td.appendChild(this.buildCheckbox(workPackage.id+'', workPackage.subject))
    }

    td.appendChild(container);
    const userAuthority = sessionStorage.getItem('isUserAdmin')
    if(attribute === 'contractorDateTime' && userAuthority === 'true'){
      // @ts-ignore
      td.oncontextmenu = (ev) => {
        console.log(workPackage.id,displayElement.innerText,)
        let self = this
        let newDate = ''
        layui.use(['jquery','layer','laydate', ], function() {
          var layer = layui.layer,$ = layui.$,
            laydate =  layui.laydate;
          layer.open({
            content:`<div class="layui-input-inline">
                    <input type="text" class="layui-input" id="signTime" placeholder="yyyy-MM-dd HH:mm:ss">
                  </div>`,
            // area: ['400px', '400px'],
            title:'Contractor DateTime',
            btn:['Confirm'],
            yes: function(index:any, layero:any){
              fetch(`/api/v3/work_packages/change_con_time`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-Authentication-Scheme':'Session',
                  'X-CSRF-TOKEN':jQuery("meta[name=csrf-token]").attr("content")||'',
                },
                body: JSON.stringify({
                  id: workPackage.id,
                  con_time: newDate
                })
              })
                .then(response => response.json())
                .then(data => {
                  // self.getSiteDiaryList()
                  self.listServe.reloadCurrentResultsList()
                  layer.close(index); //如果设定了yes回调，需进行手工关闭
                  layer.msg('Update Success', {icon: 1});
                })
                .catch(error => console.error(error))
            }
          });
          laydate.render({
            elem: '#signTime',
            type: 'datetime'
            ,isInitValue: true
            ,lang: 'en'
            ,done: function(value:any, date:any, endDate:any){
              newDate = value
              console.log(value,date,endDate); //得到日期生成的值，如：2017-08-18
            }
          });
        })
        ev.preventDefault()
        ev.stopPropagation()
      }
      console.log(td,attribute,displayElement.innerText)
    }
    console.log(attribute,displayElement.innerText)

    if(attribute == 'lateSubmission' ) {
      if(displayElement.innerText == 'yes') {
        td.dataset.lateSubmission = 'true'
      } else {
        td.dataset.lateSubmission = 'false'
      }

    }

    return td;
  }
  private buildCheckbox(id:string,name?:string) {
    let checkbox =  document.createElement('input')

    // console.log(workPackage)
    checkbox.setAttribute('type','checkbox')
    checkbox.dataset.id =id
    checkbox.onchange = ($event) => {
      let doc = $event.target as any
      if(doc.checked) {
        this.listServe.setDownLoadId(id,name)
      } else {
        this.listServe.removeDownLoadId(id)
      }
      console.log(this.listServe.getDownLoadId() )
    }
    return checkbox
  }
  public getLateSubmission() {
    return this.lateSubmission
  }
  public refresh(container:HTMLElement, workPackage:WorkPackageResource, attribute:string) {
    const displayElement = this.fieldRenderer.render(workPackage, attribute, null);

    container.innerHTML = '';
    container.appendChild(displayElement);
  }
}
