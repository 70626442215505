<div class="questContent" >
  <!-- 下载pdf -->
  <div class="downloadPDF"  *ngIf="kind_id==7" (click)="getHrefDownloadPdf()">{{i18n.t("js.check-lists.pdf.downloadPDF")}}</div>
  <div class="downloadPDF" *ngIf="kind_id!=7"><a [href]="getHref()">{{i18n.t("js.check-lists.pdf.downloadPDF")}}</a></div>
  <div class="title">
    <div class="bockicon" (click)='cleon()'></div>
    <div class="title-state" *ngIf="data.check_list.state == 'pending'">{{ i18n.t("js.check-lists.quest.Notstarted") }}</div>
    <div class="title-state" *ngIf="data.check_list.state == 'processing'" style="background-color: #ffa600">{{ i18n.t("js.check-lists.quest.Inprogress") }}</div>
    <div class="title-state" *ngIf="data.check_list.state == 'finished'" style="background-color: #087cd9;">{{ i18n.t("js.check-lists.quest.Completed") }}</div>
    <div class="title-input">
      <input #editableTitleInput
          type="p"
          [(ngModel)]="data.check_list.name"  
          class="editable-toolbar-title--input toolbar--editable-toolbar ellipsis" style="width: 100%;"/>
    </div>
    <div class="groupName"  *ngIf="(data.check_list.assigned_type == 'Group' && !data.check_list.state.includes('Complete')&& data.check_list.recheck !=='recheck' && data.check_list.state != 'New' && data.check_list.state != 'Closed' && data.check_list.state != 'Cancel') ">
        {{data.check_list.assigned_to.firstname + data.check_list.assigned_to.lastname}}
    </div>
    <div class="groupName"  *ngIf="(data.check_list.assigned_last_type == 'Group' && data.check_list.state.includes('Complete')) ">
      {{data.check_list.assigned_to_last?.firstname + data.check_list.assigned_to_last?.lastname}}
  </div>
    <div class="groupHandle" *ngIf="data.check_list.assigned_type == 'Group'&& !data.check_list.state.includes('Complete')&& data.check_list.recheck !=='recheck' && data.check_list.state != 'New'&& data.check_list.state != 'Closed' && data.check_list.state != 'Cancel' && canClickHandle()" (click)="handleClick()">
      {{!isHandle() ? 'I Handle': 'Release'}}
    </div>
    <div class="groupHandle" *ngIf="(data.check_list.assigned_last_type == 'Group' && data.check_list.state.includes('Complete')) && canClickHandle()" (click)="handleClick()">
      {{!isHandle() ? 'I Handle': 'Release'}}
    </div>
  </div>
</div>

<div class="content" *ngIf="showPdfdownload">
  <div class="content-date">
    <i class="asideShow" (click)="asideClick(true)">>></i>
    <i class="icon-close  asideHide"  (click)="asideClick(false)"></i>
    <div class="content-date-left">
      <div class="content-date-left-title">
        <svg t="1590741643954" class="icon" viewBox="0 0 1152 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5392" width="20" height="20">
          <path d="M0 0h1152v128H0V0z m0 448h768v128H0V448z m0 448h1152v128H0v-128z" p-id="5393" fill="#848c91"></path>
        </svg>
        <span>{{ i18n.t("js.check-lists.quest.outtline") }}</span>
      </div>
      <ul>
        <li *ngFor="let item of data.check_list.check_list_metum.parts;let i = index" (click)="clickLi(i)" (mouseover)="showAftername(i)" (mouseout)="hideAftername(i)">
          <div class="content-date-left-item " [class.btcontent-date-left-item]="num == i && dipsle"  (click)="asideClick(false)">
            <span class="shuliang"  [class.btname]="num == i && dipsle">{{i+1}}</span>
            <span class="name" [class.btname]="num == i && dipsle" style="width: 175px; margin: 0 30px 0 20px; text-align: left;">{{item.title}}</span>
            <span class="aftername">{{item.title}}</span>
            <span class="shuliang">{{item.count}}/{{getCount(item.items)}}</span>
          </div>
        </li>
        <hr />
        <li>
          <div class="content-date-left-item "  [class.btcontent-date-left-item]="!dipsle" (click)="clickSign(false)">
            <!-- <span class="shuliang "  [class.btname]="!dipsle">1</span> -->
            <span class="name "  [class.btname]="!dipsle" style="width: 175px; margin: 0 30px 0 20px; text-align: left;">{{ i18n.t("js.check-lists.quest.Required") }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="content-date-conter" >
      <ng-container *ngIf="dipsle">
        <div class="content-data-conter-title">
          <div class="content-data-cinter-title-text">
            <h2 style="margin-right: 20px;">{{num+1}}</h2>
            <h2>{{data.check_list.check_list_metum.parts[num].title}}</h2>
          </div>
          <div class="jingdutiao">
            <span class="jiangdutiao-text">{{data.check_list.check_list_metum.parts[num].count}}/{{getCount(data.check_list.check_list_metum.parts[num].items)}} {{ i18n.t("js.check-lists.quest.Signed") }}</span>
            <span class="jingdutiao-progress-bar">
              <span class="jingdutiao-install-bar"  [style.width.%]="data.check_list.check_list_metum.parts[num].count/getCount(data.check_list.check_list_metum.parts[num].items)*100"></span>
            </span>
          </div>
        </div>  
        <!-- <div class="all" *ngIf="this.data.check_list.state !== 'Closed'" (click)="allLogShow()">
          All
          <div class="allLog" (click)="$event.stopPropagation()" *ngIf="allLog">
            Select All
            <span class="point"></span>
            <div class="check">
              <span>Yes</span>
              <input type="radio"
              value="1"
              [(ngModel)]="checkYes"
              (ngModelChange)="check(0)"
              />
            </div>
            <div class="check">
              <span>
                No
              </span>
              <input
              type="radio"
              value="0"
              [(ngModel)]="checkYes"
              (ngModelChange)="check(1)"
              />
            </div>
            <div class="check">
              <span>
                N/A
              </span>
              <input
              type="radio"
              value="2"
              [(ngModel)]="checkYes"
              (ngModelChange)="check(2)"
              />
            </div>
          </div>  
        </div> -->
        <div class="content-date-conter-describe">
          <pre style="padding-bottom: 8px;font-size: 13px;">{{data.check_list.check_list_metum.parts[num].description}}</pre>
        </div>
        <div class="content-date-conter-item" *ngFor="let item of data.check_list.check_list_metum.parts[num].items;let i = index">
          <div class="Dropzone">
            <span class="SectionItem__index">{{num+1}}.{{i+1}}</span>
            <!--*********************************开始的地方***********************************************-->
            <ul class="dropZoneUl">
              <li *ngFor="let subitem of item.list;let subindex=index" class="zoneLi">
                <div class="SectionItem__content" style="width: 100%;">

                  <div class="SectionItem__content-field">  <span class="wrapspan">{{subitem.title}} </span>  <i *ngIf="subitem.necessary===false" style="color: 84969e;margin-left: 10px;">({{ i18n.t("js.check-lists.template.optional") }})</i></div>
                  <div class="SectionItem__instructions">
                    <div class="SectionItem__instructions-title" *ngIf="subitem.description">{{ i18n.t("js.check-lists.quest.Description") }}:</div>
                    <div class="SectionItem__instructions-text">{{subitem.description}}</div>
                  </div>
                  <input class="SectionItem__response-text-editable" [id]="'input_getinfo'+i+subindex" [disabled]="checkDisable()"  *ngIf="subitem.type == 'text'"  (click)="input_getinfoInputChange(i,subindex)"  (blur)="updateUserInfo($event,i,subindex)"   [(ngModel)]="subitem.value"  type="text"/>
                  <span  *ngIf="subitem.type == 'radio'">
                    <span  *ngFor="let t of subitem.options;let j = index" style="margin-left: 30px;">
                      <input style="vertical-align: middle;width: 20px; height: 20px;" [disabled]="checkDisable()"  (ngModelChange)="changeSelect($event,j,i,subindex)" [checked]="j === subitem.value"  [type]="subitem.type" [(ngModel)]="t" />
                      <span style="margin-left: 10px;">{{t}}</span>
                    </span>
                  </span>
                  <span *ngIf="subitem.type == 'textarea'">
                      <textarea class="textareaContent" [disabled]="checkDisable()" (click)="input_getinfoInputChange(i,subindex)"  (blur)="updateUserInfo($event,i,subindex)" [(ngModel)]="subitem.value">

                      </textarea>
                  </span>
                  <span  *ngIf="subitem.type == 'checkbox'">
                    <span  *ngFor="let t of subitem.options; let j = index" style="margin-left: 30px;display: block;margin-bottom: 10px;">
                        <input style="vertical-align: middle;width: 20px; height: 20px;" [disabled]="checkDisable()" [type]="subitem.type"  (change)="changeCheckbox($event,j,i,subindex,subitem)"  [(ngModel)]="j"   [checked]="subitem.value.includes(j)" />
                        <span style="margin-left: 10px;">{{t}}</span>
                    </span>
                  </span>
                  <!--另外的情况-->
                  <div *ngIf="subitem.type=='date'">
                    <!-- <input type="date"  [(ngModel)]="subitem.value"  (blur)="changeDate($event,i,subindex)"> -->
                    <div class="summary-value" [id]="'period_end'+i+subindex" style="width: 250px;height: 38px;border: 1px solid #bbb;"  (click)="subitem.type = 'null' ; subitem.type = 'date';period_endChange(subitem.value,i,subindex,subitem)">{{subitem.value}}</div>
                  </div>

                  <div *ngIf="subitem.type =='select'">
                    <select [disabled]="checkDisable()" style="width: 250px;line-height: unset;" [(ngModel)]="subitem.value" style="width: 250px;line-height: unset;" (click)="input_getinfoInputChange(i,subindex)" (change)="newSelect($event,i,subindex)">
                      <option [value]="optionitem" *ngFor="let optionitem of subitem.options">{{optionitem}}</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
        <!--*********************************结束的地方***************************-->
          </div>
          <div class="ChecklistItemAddAttachmentsStrip">
            <!-- <button class="Button--link-secondary" (click)="issueblock = true">
              <svg t="1590993757327" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2635" width="17" height="17"><path d="M950.784 812.544L567.296 147.968c-24.576-42.496-86.016-42.496-110.08 0l-384 664.576c-24.576 42.496 6.144 95.744 55.296 95.744h766.976c49.152-0.512 79.872-53.248 55.296-95.744zM482.816 391.68c0-16.384 13.312-29.184 29.184-29.184 16.384 0 29.184 13.312 29.184 29.184v297.984c0 16.384-13.312 29.184-29.184 29.184-16.384 0-29.184-13.312-29.184-29.184V391.68z m29.184 420.352c-16.384 0-30.208-13.312-30.208-30.208 0-16.384 13.312-30.208 30.208-30.208 16.384 0 30.208 13.312 30.208 30.208 0 16.896-13.824 30.208-30.208 30.208z" fill="#819099" p-id="2636"></path></svg>
              <span class="ChecklistItemAddAttachmentsStrip__button-text">{{ i18n.t("js.check-lists.quest.Issue") }}</span>
            </button> -->
            <button class="Button--link-secondary" (click)="showNote(item,i)">
              <svg t="1590994468040" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3528" width="17" height="17"><path d="M600.76158 68.191078 245.714238 68.191078c-49.040926 0-88.317465 39.720653-88.317465 88.76158l-0.444115 710.09366c0 49.040926 39.277562 88.76158 88.317465 88.76158l533.014616 0c49.040926 0 88.76158-39.720653 88.76158-88.76158L867.046319 334.476841 600.76158 68.191078zM689.523159 778.284739 334.475817 778.284739l0-88.76158 355.047342 0L689.523159 778.284739zM689.523159 600.76158 334.475817 600.76158l0-88.76158 355.047342 0L689.523159 600.76158zM556.38079 378.857631 556.38079 134.762263 800.475134 378.857631 556.38079 378.857631z" p-id="3529" fill="#819099"></path></svg>
              <span class="ChecklistItemAddAttachmentsStrip__button-text">{{ i18n.t("js.check-lists.quest.note") }}</span>
            </button>
            <button class="Button--link-secondary" (click)="uploadImg(item,i)">
              <svg t="1590994558068" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4505" width="17" height="17"><path d="M512.000512 344.135828c-100.70868 0-182.350095 81.640392-182.350095 182.348048s81.641415 182.349071 182.350095 182.349071c100.70561 0 182.348048-81.641415 182.348048-182.349071S612.706121 344.135828 512.000512 344.135828z" p-id="4506" fill="#819099"></path><path d="M879.229291 229.637074 754.566036 229.637074l-75.05951-83.400478L341.665055 146.236596l-72.67623 83.400478-124.219139 0c-44.26004 0-80.139202 36.56273-80.139202 81.669044l0 484.787218c0 45.105291 35.879162 81.669044 80.139202 81.669044l734.459604 0c44.262086 0 80.139202-36.56273 80.139202-81.669044L959.368492 311.306118C959.369515 266.200828 923.491377 229.637074 879.229291 229.637074zM512.000512 799.301402c-150.673651 0-272.817526-122.143874-272.817526-272.817526 0-150.671605 122.143874-272.816502 272.817526-272.816502 150.671605 0 272.815479 122.143874 272.815479 272.816502C784.814967 677.157527 662.672116 799.301402 512.000512 799.301402z" p-id="4507" fill="#819099"></path></svg>            
              <span class="ChecklistItemAddAttachmentsStrip__button-text">{{ i18n.t("js.check-lists.quest.photo") }}</span>
              <div (click)="service.stopPropagation($event)" style="display: none;">
                <input #uploadFileRef type="file" (change)="selectFile($event,item,i)">
              </div>
            </button>
          </div>


          <!-- 注释 ----------------------------------------------------------------------->
            <div class="ChecklistSectionItemAttachments__attachments" *ngIf="(checkObj(item.props)||((remark_id==i)&&remarkFlag))">
            <div class="ChecklistSectionItemAttachments__attachment-component">
              <div class="ChecklistItemPhotos__avatar-container">
                <div class="UserAvatar">
                  <div class="UserAvatar__initials-text">SW</div>
                </div>
              </div>
              <!-- picture -->
              <div *ngIf="item.props.image">
                <div class="ChecklistItemPhotos__thumbnail-strip" *ngIf="item.props.image.length">
                <div class="ThumbnailPicture__image-container viewDiv" *ngFor="let img of item.props.image;let k = index">
                  <img   [src]="host+'/api/v3/logical_docs/doc/'+img.doc_id+'/stream'"/>
                  <span style="margin-left:8px">{{img.status}}</span>
                  <div style="width: 70px;height: 70px;position: absolute;top: 0;" (click)="toEditpic(k,item.props.image,img,i)"></div>
                </div>
              </div>
            </div>
              <!-- <div class="ChecklistItemPhotos__thumbnail-strip" *ngIf="!item.props.image.length&&doc_item_id!=i">
                <div class="ThumbnailPicture__image-container viewDiv" *ngFor="let img of item.props.image;let k = index">
                  <img  [src]="host+'/api/v3/logical_docs/doc/'+img.doc_id+'/stream'"/>
                  <span style="margin-left:8px">{{img.status}}</span>
                  456789
                  <div style="width: 70px;height: 70px;position: absolute;top: 0;" (click)="toEditpic(k,item.props.image,img,i)"></div>
                </div>
              </div>
              <div class="ChecklistItemPhotos__thumbnail-strip" *ngIf="!item.props.image.length&&doc_item_id==i">
                <div class="ThumbnailPicture__image-container viewDiv" *ngFor="let img of templateid;let k = index">
                  <img  [src]="host+'/api/v3/logical_docs/doc/'+img.doc_id+'/stream'"/>
                  <span style="margin-left:8px">{{img.status}}</span>
                  789123
                  <div style="width: 70px;height: 70px;position: absolute;top: 0;" (click)="toEditpic(k,item.props.image,img,i)"></div>
                </div>
              </div> -->
            </div>
            <div class="ChecklistSectionItemAttachments__attachment-component" *ngIf="((remark_id==i)&&remarkFlag)||item.props.remark">
              <div class="ChecklistItemNote__well">
                <div class="TextInput__editable">
                  <input type="text" [(ngModel)]="item.props.remark" [disabled]="checkDisable()"  #nameit  (blur)="addRemark($event,i)" placeholder="Add comments" />
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------------------------------------------------------- -->
        </div>
        <div class="CompleteSelectedSection">
          <button class="SaveButton__save-button" *ngIf="data.check_list.check_list_metum.parts[num].state != 'finished'"  (click)="wanchengpardclick(data.check_list.check_list_metum.parts[num].items)">{{ i18n.t("js.check-lists.quest.done") }}</button>
          <span style="color: #008a00;" class="CompleteSelectedSection__items-remaining"  *ngIf="data.check_list.check_list_metum.parts[num].state != 'finished'">
            {{needNum==0? i18n.t("js.check-lists.quest.requiredquanbu"):(hascount) +i18n.t("js.check-lists.quest.requiredidex")}} 
            <span *ngIf="showNum">({{needNum}}{{i18n.t("js.check-lists.template.required")}})</span>
          </span>
          <span  style="color: #008a00;" class="CompleteSelectedSection__items-remaining"   *ngIf="data.check_list.check_list_metum.parts[num].state == 'finished'">
            {{i18n.t("js.check-lists.quest.requiredquanbu")}}
          </span>
        </div>

      </ng-container>
      <ng-container *ngIf="!dipsle">
        <!-- 签名 -->
        <div class="content-data-conter-title">
          <div class="content-data-cinter-title-text">
            <!-- <h2 style="margin-right: 20px;">1</h2> -->
            <h2>{{i18n.t("js.check-lists.quest.Required")}}</h2>
          </div>
        </div>
        <div>
          <div class="require" >
            <div>Action Required</div>
            <textarea   [(ngModel)]="data.check_list.action_required" [disabled]="actionRequirBoolean()?'disabled': false"> </textarea>
            <check-field-dateTime id="submission_sign_at" [value]="data.check_list.submission_sign_at" (changeValue)="changeSign('submission',$event)" [ngClass]="{'DateDisabled': actionRequirBoolean()}" ></check-field-dateTime>
            <div *ngIf="actionRequirBoolean()">
              {{data.check_list.submission_sign_at}}
            </div>
          </div>
         <div class="action">

           <div class='attributes-key-value--value-container' 
           [ngClass]="{'canNotSign': data.check_list.created_by_id != userId || (data.check_list.state !== 'New'&& data.check_list.state != 'Completed'  && !!data.check_list.recheck == false )}"
           style="display: flex; flex-direction: column;">
           <span>{{data.check_list.created_by_user + '(' + data.check_list.created_by_position + ')' }}  Sign</span>
             <!-- handler 下拉框下面的文字 -->
             <span style="margin: 10px 0;" ></span>
             <div class="attributes-key-value--value-container-content" 
               *ngIf="!actionSign && !signloding"
               (click)="displaysign('action')"
              >
               <div class="attributes-key-value--value-container-content-icon"
               >
                 <span class="icon-add" style="color: #fff;"></span>
               </div>
             </div>
             <div class="attributes-key-value__loading" *ngIf="signloding">
               <div class="loading-indicator -compact">
               <div class="block-1"></div>
               <div class="block-2"></div>
               <div class="block-3"></div>
               <div class="block-4"></div>
               <div class="block-5"></div>
               </div>
             </div> 
             <div class="deleteSign" *ngIf="kind_id == 1 && actionSign && data.check_list.created_by_id == userId && (data.check_list.state == 'New' ||  data.check_list.state .includes('Completed') )" (click)="deleteActionSign();actionCheckBoolean = false" >X</div>
             <div class="deleteSign" *ngIf="kind_id != 1 && actionSign && ((data.check_list.created_by_id == userId &&
             (data.check_list.state == 'New' ||  data.check_list.recheck == 'recheck') ))" (click)="deleteActionSign()" >X</div>

             <img class="signImg"  [src]='actionSign' *ngIf="actionSign"/>
             <div>
               <input type="checkbox" [(ngModel)]="actionCheckBoolean" *ngIf="checkSign('action')" (change)="defaultCheck('action', $event)"/> 默认签名
             </div>
         
           </div>
         </div> 
        </div>
        <div class="require" *ngIf="data.check_list.state !== 'New' && data.check_list.recheck !== 'recheck'&&data.check_list.recheck!='recheck_two'"  >
        <div>Reply</div>
        <textarea   [(ngModel)]="data.check_list.reply" [disabled]="actionreplayBoolean()?'disabled': false"> </textarea>
        <check-field-dateTime  [value]="data.check_list.inspection_first_sign_at" (changeValue)="changeSign('inspection_first_sign_at',$event)" [ngClass]="{'DateDisabled': actionreplayBoolean()}" ></check-field-dateTime>
        <div *ngIf="actionreplayBoolean()">
          {{data.check_list.inspection_first_sign_at}}
        </div>
      </div>
        <div class='attributes-key-value--value-container' *ngIf="data.check_list.state !== 'New' && data.check_list.recheck !== 'recheck'&&data.check_list.recheck!='recheck_two'"    [ngClass]="{'canNotSign': (data.check_list.assigned_to_id != userId && data.check_list.executor_id != userId) ||  data.check_list.recheck == 'recheck'  || !data.check_list.state.includes('Sent')}"  style="display: flex; flex-direction: column;">
          <!-- handler 下拉框下面的文字 -->
          <span *ngIf="data.check_list.assigned_type == 'User' ||
          (data.check_list.executor_id && !data.check_list.recheck)">{{data.check_list.executor_id?data.check_list.executor.firstname +  ' ' +
            data.check_list.executor.lastname + '(' + data.check_list.executor.position + ')':  data.check_list.assigned_to.firstname + ' ' +
            data.check_list.assigned_to.lastname + '(' + data.check_list.assigned_to.position + ')'}} Sign</span>
          <span style="margin: 10px 0;" ></span>
          <div class="attributes-key-value--value-container-content" 
          *ngIf="!replySign && !reSignLoad"
            (click)="displaysign('reply')"
           >
            <div class="attributes-key-value--value-container-content-icon"
            >
              <span class="icon-add" style="color: #fff;"></span>
            </div>
          </div>
          <div class="attributes-key-value__loading" *ngIf="reSignLoad">
            <div class="loading-indicator -compact">
            <div class="block-1"></div>
            <div class="block-2"></div>
            <div class="block-3"></div>
            <div class="block-4"></div>
            <div class="block-5"></div>
            </div>
          </div> 
          <div class="deleteSign" *ngIf="replySign && (data.check_list.assigned_to_id == userId || data.check_list.executor_id == userId)&& data.check_list.state.includes('Sent') && data.check_list.recheck != 'recheck'" (click)="deleteReplySign();replyCheckBoolean = false" >X</div>
          <img class="signImg"  [src]='replySign' *ngIf="replySign"/>
          <div>
             <input type="checkbox" [(ngModel)]="replyCheckBoolean" *ngIf="checkSign('reply')" (change)="defaultCheck('reply', $event)" /> 默认签名
          </div>
      
        </div>
        <div class="require" *ngIf="data.check_list.state =='ConsApproving'||data.check_list.state.includes('Completed')|| data.check_list.state == 'Closed'|| data.check_list.state == 'Cancel' && kind_id == 3"  >
          <div>Reply</div>
          <textarea   [(ngModel)]="data.check_list.reply_last" [disabled]="actionreplay2Boolean()?'disabled': false"> </textarea>
          <check-field-dateTime  [value]="data.check_list.inspection_second_sign_at" (changeValue)="changeSign('inspection_second_sign_at',$event)" [ngClass]="{'DateDisabled': actionreplay2Boolean()}" ></check-field-dateTime>
          <div *ngIf="actionreplay2Boolean()">
            {{data.check_list.inspection_second_sign_at}}
          </div>
        </div>
        <div class='attributes-key-value--value-container' *ngIf="data.check_list.state =='ConsApproving'||data.check_list.state.includes('Completed')|| data.check_list.state == 'Closed'|| data.check_list.state == 'Cancel' && kind_id == 3"    [ngClass]="{'canNotSign': (data.check_list.assigned_to_last_id != userId && data.check_list.executor_last_id != userId) ||  data.check_list.recheck == 'recheck_two' }"  style="display: flex; flex-direction: column;">
            <!-- handler 下拉框下面的文字 -->
            <span *ngIf="data.check_list.recheck !== 'recheck' && data.check_list.assigned_last_type == 'User' ||
            (data.check_list.executor_last_id && data.check_list.recheck !== 'recheck_two')">{{data.check_list.executor_last_id?data.check_list.executor_last.firstname +  ' ' +
              data.check_list.executor_last.lastname + '(' + data.check_list.executor_last.position + ')':  data.check_list.assigned_to_last.firstname + ' ' +
              data.check_list.assigned_to_last.lastname + '(' + data.check_list.assigned_to_last.position + ')'}} Sign</span>
            <span style="margin: 10px 0;" ></span>
            <div class="attributes-key-value--value-container-content"
            *ngIf="!assigned_doc_last_sign && !assigned_doc_last_loading"
              (click)="displaysign('replytwo')"
             >
              <div class="attributes-key-value--value-container-content-icon"
              >
                <span class="icon-add" style="color: #fff;"></span>
              </div>
            </div>
            <div class="attributes-key-value__loading" *ngIf="assigned_doc_last_loading">
              <div class="loading-indicator -compact">
              <div class="block-1"></div>
              <div class="block-2"></div>
              <div class="block-3"></div>
              <div class="block-4"></div>
              <div class="block-5"></div>
              </div>
            </div>
            <div class="deleteSign" *ngIf="assigned_doc_last_sign && (data.check_list.assigned_to_last_id == userId || data.check_list.executor_last_id == userId)&& (data.check_list.state.includes('Completed')||data.check_list.state =='ConsApproving' )" (click)="deleteSssignedLast()" >X</div>
            <img class="signImg"  [src]='assigned_doc_last_sign' *ngIf="assigned_doc_last_sign"/>


        </div>
        <div class="Button" *ngIf="data.check_list.state !== 'Closed'&& data.check_list.state != 'Cancel' && kind_id == 1">
          <div class="Save" *ngIf="!data.check_list.state.includes('Completed')" (click)="AssignSave(data.check_list.state)"
          [ngClass]="{'canNotClick': (data.check_list.created_by_id != userId 
          || (data.check_list.state == 'Sent'  && data.check_list.recheck !== 'recheck')) 
          && ((data.check_list.assigned_to_id != userId 
          && data.check_list.executor_id != userId)
          || data.check_list.state !== 'Sent')}">Save</div>
          <div class="assign"  *ngIf="data.check_list.created_by_id == userId && data.check_list.state !== 'Sent'" (click)="ifCheckAssign = true" >Assign</div>
          <div class="complete" (click)="AssignSave('Completed')" *ngIf="(data.check_list.assigned_to_id == userId || data.check_list.executor_id == userId) && replySign&& !data.check_list.state.includes('Completed') " >
            Complete
          </div>
          <div class="closed" (click)="AssignSave('Closed')" *ngIf="data.check_list.created_by_id == userId && data.check_list.includes('Completed')" >
            Closed
          </div>
        </div>
        <div class="Button Daily" *ngIf="kind_id != 1">
         <p>
           <span class="checkorclose" *ngIf="(data.check_list.state.includes('Sent') && data.check_list.recheck !==  'recheck') || data.check_list.state.includes('Completed') || data.check_list.state =='ConsApproving' ">
             
             <!-- <div class="recheck" *ngIf="kind_id == 2" (click)="AssignSave('Recheck')"
             [ngClass]="{'canNotClick': !(data.check_list.assigned_to_id == userId
             || data.check_list.executor_id == userId ) }">
               Recheck2
             </div> -->
             <div class="recheck" *ngIf="kind_id != 1&&data.check_list.recheck != 'recheck_two'"  (click)="AssignSave('Recheck')"
             [ngClass]="{'canNotClick': (data.check_list.state.includes('Sent') && data.check_list.assigned_to_id != userId
             && data.check_list.executor_id != userId) || (data.check_list.state.includes('Completed') && data.check_list.assigned_to_last_id != userId
             && data.check_list.executor_last_id != userId) }">
               Recheck
             </div>
             <div class="closed" *ngIf="(kind_id == 2 && data.check_list.state.includes('Completed') )"
             (click)="AssignSave('Closed')"
             [ngClass]="{'canNotClick':  ( !assigned_doc_last_sign || (data.check_list.assigned_to_last_id != userId
             && data.check_list.executor_last_id != userId) )}">
               Closed
             </div>
             <div class="closed" *ngIf="(kind_id == 3 && data.check_list.state.includes('Completed'))"
             (click)="AssignSave('Closed')"
             [ngClass]="{'canNotClick':  ( !assigned_doc_last_sign || (data.check_list.assigned_to_last_id != userId
             && data.check_list.executor_last_id != userId) )}">
               Closed
             </div>

             <div class="closed" *ngIf="kind_id == 7 && data.check_list.state =='ConsApproving'"
                  (click)="AssignSave('Closed')"
                  [ngClass]="{'canNotClick':  ( !assigned_doc_last_sign || (data.check_list.assigned_to_last_id != userId
             && data.check_list.executor_last_id != userId) )}">
               Closed
             </div>


             <div class="assign"   [ngClass]="{'canNotClick': (!replySign || (data.check_list.executor_id != userId && data.check_list.assigned_to_id != userId ))&&signType!='action'}"
             *ngIf="data.check_list.state.includes('Sent') && (data.check_list.recheck == 'recheck' || kind_id != 1)&& (data.check_list.recheck != 'recheck_two' && kind_id != 1)"
             (click)="(data.check_list.executor_id == userId || data.check_list.assigned_to_id == userId ) &&replySign && ifCheckAssign = true" >Assign</div>
          </span>
         </p>
         <p>
           <span class="SaveButton">
             <div class="Save"  *ngIf="!(data.check_list.state.includes('Completed')||data.check_list.state=='ConsApproving')"  (click)="AssignSave(data.check_list.state, true)"  [ngClass]="{'canNotClick': (data.check_list.created_by_id != userId  ||
             (data.check_list.state !== 'New'&& !data.check_list.state.includes( 'Completed') && data.check_list.recheck !=='recheck')) && ((data.check_list.assigned_to_id != userId && data.check_list.executor_id != userId)|| !data.check_list.state.includes( 'Sent'))}">Save</div>
             <div class="assign" [ngClass]="{'canNotClick': !actionSign}"  *ngIf="data.check_list.created_by_id == userId
             && (data.check_list.state == 'New' || data.check_list.recheck == 'recheck'|| data.check_list.recheck == 'recheck_two')"
             (click)="actionSign && ifCheckAssign = true" >Assign</div>
           </span>
         </p>
        </div>
        <div *ngIf="ifCheckAssign" >
          <div class="assigngroupCheck" *ngIf="kind_id != 1">
            <h2 class="title">Select an Individual or a group</h2>
            <div class="selectAssignType">
              <div>
        
                <input class="radio_type" type="radio" name="type" id="radio1" (change)="changeAssignTo('Indlvidual')" checked="checked"/> 
                <label for="radio1">Individual</label>
              </div>
              <div>
        
                <input class="radio_type" type="radio" (change)="changeAssignTo('group')" name="type" id="radio2" /> 
                <label for="radio2">Group</label>
              </div>
            </div>
        
            <div class="table">
            <table>
              <thead>
                <tr>
                  <th class="assign">Assign to</th>
                  <th class="name"></th>
                  <th class="cc:">cc:</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of checkassightselect">
                  <td>
                    <input class="checkbox_type" type="radio" name="assignId" [value]="item.id"  [(ngModel)]="constructorUserId"  /> 
                  </td>
                  <td class="name">
                    <div>
                      {{item.name}}
                    </div>
                    <div *ngIf="assignType == 'Indlvidual'">
                      {{item.position? item.position: '——'}}
                    </div>
                  </td>
                  <td><input class="checkbox_type" type="checkbox" [value]="item.id" name="type" [(ngModel)]="item.checked"   /> </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="assignButton">
        
            <div class="cancel" (click)="ifCheckAssign = false;changeAssignTo('Indlvidual')" >
              Cancel
            </div>
            <div class="confirm" (click)="AssignSave((data.check_list.state == 'Sent' && !!data.check_list.recheck == false)? 'Completed': 'Sent')" [ngClass]="{'canNotClick': !constructorUserId}">
              Confirm
            </div>
          </div>
          </div>
          <div class="assignCheck" *ngIf="kind_id == 1">
            <span class="title">Assign To</span>
            <div class="close" (click)="ifCheckAssign = false;changeAssignTo('Indlvidual')">X</div>
            <select [(ngModel)]="constructorUserId">
             
              <option [value]="item.id" *ngFor="let item of checkassightselect">{{item.name}}</option>
            </select>
            <div class="check" (click)="AssignSave('Sent')" [ngClass]="{'canNotClick': !constructorUserId}">
              Assign
            </div>
          </div>
        </div>

        <!-- <div class="content-date-conter-item"  *ngFor="let item of data.check_list.check_list_metum.signs;let i = index">
          <div class="Dropzone" >
            <span class="SectionItem__index">{{i18n.t("js.check-lists.quest.Signature")}} {{i+1}}</span>
            <div class="SectionItem__content">
              <div class="SectionItem__content-field">{{i18n.t("js.check-lists.quest.xuqiuezhe")}}：{{item.username}}</div>
                签名限制
              <div class="SectionItem__content__bottom-container" id="signer" (click)='lodingqianming(i,item)'>
                
               
                <div class="SectionItem__content__bottom-content" >
                  <div *ngIf="!item.doc_id" style="width: 100%; height: 80px;"></div>
                  <img id="SectionItemid" style="width: 100%;"  *ngIf="item.doc_id"  [src]="host+'/api/v3/logical_docs/doc/'+item.doc_id+'/stream'"/>
                  <hr class="B360Hr" style="height: 2px; background-color: #000;    margin: 10px 0;">
                  <span *ngIf="item.doc_id" class="">{{autograph(item.username,item.finished_at)}}</span>
                </div>

                
              </div>
            </div>
          </div>
        </div> -->
    </ng-container>
      <!-- 分页 -->
      <checkListSign *ngIf="dipslesignature" (closeView)='closeView($event)'></checkListSign>
      <div class="BaseSectionsPagination__bottom-navigation" *ngIf="dipsle">
        <div style="width: 350px;">
          <!-- 往前 -->
          <div class="BaseSectionsPagination__nav-section BaseSectionsPagination__nav-section--previous"  *ngIf="num>0" style="width: 350px;height: 75px;" (click)="changeNum(-1)">
            <div class="BaseSectionsPagination__nav-section-text">
              <span class="BaseSectionsPagination__nav-section-title" style="text-align: right;">{{i18n.t("js.check-lists.quest.Inspection")}}</span>
              <span class="BaseSectionsPagination__nav-section-name-container">
                <span class="BaseSectionsPagination__nav-section-index">{{num}}</span>
                <span class="BaseSectionsPagination__nav-section-name">{{data.check_list.check_list_metum.parts[num-1].title}}</span>
              </span>
            </div>
            <div class="ChecklistSectionsPagination__completion-status">
                <div class="ChecklistSectionsPagination__preview-counters" style="flex-direction: row-reverse;">
                  <span>{{data.check_list.check_list_metum.parts[num-1].count}}/{{getCount(data.check_list.check_list_metum.parts[num - 1].items)}}</span>
                  <svg style="transform: rotate(180deg);" t="1591001748506" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7718" width="24" height="14"><path d="M1010.80577 455.530292a29.72694 29.72694 0 0 1 0 42.037895L758.668226 749.785575a29.714963 29.714963 0 0 1-42.017934-42.027914l252.137544-252.227369a29.720951 29.720951 0 0 1 42.017934 0zM758.668226 203.312904l252.137544 252.217388a29.717957 29.717957 0 0 1-42.017934 42.037895l-252.137544-252.207407a29.721949 29.721949 0 0 1 42.017934-42.047876zM27.945419 447.20655h955.693411a20.460039 20.460039 0 0 1 20.460039 20.460039v18.254347a20.460039 20.460039 0 0 1-20.460039 20.460039H27.945419A20.460039 20.460039 0 0 1 7.48538 485.920936v-18.254347a20.460039 20.460039 0 0 1 20.460039-20.460039z" fill="#bcc9d1" p-id="7719"></path></svg>
                </div>
                <span class="jingdutiao-progress-bar">
                  <span class="jingdutiao-install-bar"  [style.width.%]="data.check_list.check_list_metum.parts[num-1].count/getCount(data.check_list.check_list_metum.parts[num - 1].items)*100"></span>
                </span>
            </div>
          </div>
        </div>
          <!-- 往后 -->
       <div style="width: 350px;">
        <div class="BaseSectionsPagination__nav-section BaseSectionsPagination__nav-section--next"  *ngIf="data.check_list.check_list_metum.parts.length != num+1"  (click)="changeNum(1)" style="width: 350px;height: 75px;">
          <div class="BaseSectionsPagination__nav-section-text">
            <span class="BaseSectionsPagination__nav-section-title">{{i18n.t("js.check-lists.quest.Response")}}</span>
            <span class="BaseSectionsPagination__nav-section-name-container">
              <span class="BaseSectionsPagination__nav-section-index">{{num+2}}</span>
              <span class="BaseSectionsPagination__nav-section-name">{{data.check_list.check_list_metum.parts[num+1].title}}</span>
            </span>
          </div>
          <div class="ChecklistSectionsPagination__completion-status">
              <div class="ChecklistSectionsPagination__preview-counters">
                <span>{{data.check_list.check_list_metum.parts[num+1].count}}/{{getCount(data.check_list.check_list_metum.parts[num + 1].items)}}</span>
                <svg t="1591001748506" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7718" width="24" height="14"><path d="M1010.80577 455.530292a29.72694 29.72694 0 0 1 0 42.037895L758.668226 749.785575a29.714963 29.714963 0 0 1-42.017934-42.027914l252.137544-252.227369a29.720951 29.720951 0 0 1 42.017934 0zM758.668226 203.312904l252.137544 252.217388a29.717957 29.717957 0 0 1-42.017934 42.037895l-252.137544-252.207407a29.721949 29.721949 0 0 1 42.017934-42.047876zM27.945419 447.20655h955.693411a20.460039 20.460039 0 0 1 20.460039 20.460039v18.254347a20.460039 20.460039 0 0 1-20.460039 20.460039H27.945419A20.460039 20.460039 0 0 1 7.48538 485.920936v-18.254347a20.460039 20.460039 0 0 1 20.460039-20.460039z" fill="#bcc9d1" p-id="7719"></path></svg>
              </div>
              <span class="jingdutiao-progress-bar">
                <span class="jingdutiao-install-bar"  [style.width.%]="data.check_list.check_list_metum.parts[num+1].count/getCount(data.check_list.check_list_metum.parts[num+1].items)*100"></span>
              </span>

          </div>
        </div>
      </div>
       
      </div>
    </div>
  </div>



  <i class="detailShow" (click)="detailClick(true)"><< </i>
  <div class="content-detailed">
    <div class="ChecklistPageRightPanel__header">
      <div class="ChecklistPageRightPanel__title">{{i18n.t("js.check-lists.quest.Details.Detailstext")}}</div>
      <button class="ChecklistPageRightPanel__close-button icon-close"  (click)="detailClick(false)"></button>
    </div>
    <div class="ChecklistPageRightPanel__counters">
      <div class="PreviewCountersIconLabel">
        <label class="IconLabel">
          <svg class="SvgIcon SvgIcon--store-icon" width="16px" height="16px">
            <use xlink:href="#sections" href="#sections"></use>
          </svg>
          <span class="IconLabel__value">{{arr_sum.length}}/{{data.check_list.check_list_metum.parts.length}} {{i18n.t("js.check-lists.quest.Details.Sections")}}</span>
        </label>
      </div>
      <div class="PreviewCountersIconLabel">
        <label class="IconLabel">
          <svg class="SvgIcon SvgIcon--store-icon" width="16px" height="16px">
            <use xlink:href="#sections" href="#sections"></use>
          </svg>
          <span class="IconLabel__value">{{sum}}/{{sum_num}} {{i18n.t("js.check-lists.quest.Details.Items")}}</span>
        </label>
      </div>
    </div>

     
    <hr class="B360Hr">
      <div class="Field--vertical">
        <label class=" Field__label">{{i18n.t("js.check-lists.quest.Details.Type")}}:</label>
        <div class="ChecklistPageRightPanel__editable-assignee">
          <!-- <input type="text" id="checklostsdate"> -->
          <div class="Editable__text">{{data.check_list.check_list_type&&data.check_list.check_list_type.name || i18n.t("js.check-lists.quest.Details.Unspecified")}}</div>
        </div>
      </div>
      <div class="Field--vertical">
        <label class=" Field__label">{{i18n.t("js.check-lists.quest.Details.Description")}}:</label>
        <div class="ChecklistPageRightPanel__editable-assignee">
          <div class="Editable__text">{{data.check_list.description || ''}}</div>
        </div>
      </div>
      <div class="Field--vertical">
        <label class=" Field__label">{{i18n.t("js.check-lists.quest.Details.number")}}:</label>
        <div class="ChecklistPageRightPanel__editable-assignee">
          <div class="Editable__text">{{data.check_list.no || ''}}</div>
        </div>
      </div>
      <div class="Field--vertical">
        <label class=" Field__label">{{i18n.t("js.check-lists.quest.Details.Signatures")}}:</label>
        <div class="ChecklistPageRightPanel__editable-assignee">
          <div class="Editable__text">{{count_doc.length}}/{{data.check_list.check_list_metum.signs.length}} {{i18n.t("js.check-lists.quest.Details.Signed")}} </div>
        </div>
      </div>
      <div *ngIf="isFileRefNo">
        <div  *ngIf="saveFileFlag">
          <hr class="B360Hr">
          <h3 style="padding: 5px 10px;" class="attributes-group--header-text">{{i18n.t("js.file-ref-no.title")}}</h3>
          <div class="Field--vertical">
            <label class=" Field__label">{{i18n.t("js.file-ref-no.prefix")}}:</label>
            <div class="ChecklistPageRightPanel__editable-assignee">
              <div class="Editable__text">{{ fileRefNo.prefix }}</div>
            </div>
          </div>
          <div class="Field--vertical">
            <label class=" Field__label">{{i18n.t("js.file-ref-no.id")}}:</label>
            <div class="ChecklistPageRightPanel__editable-assignee">
              <div class="Editable__text">-</div>
            </div>
          </div>
          <div class="Field--vertical">
            <label class=" Field__label">{{i18n.t("js.file-ref-no.type")}}:</label>
            <div class="ChecklistPageRightPanel__editable-assignee">
              <div class="Editable__text">
                <select class="attribute-fileRefNo-select" [(ngModel)]="fileRefNoType" [disabled]="!saveFileFlag?true:false">
                  <option  *ngFor="let item of fileRefNo.values; trackBy:trackByName" [value]="getStringValue(item.value)">
                  {{item.value.value}}
                  </option>
              </select>
              </div>
            </div>
          </div>
          <div class="Field--vertical">
            <label class=" Field__label">{{i18n.t("js.file-ref-no.suffix")}}:</label>
            <div class="ChecklistPageRightPanel__editable-assignee">
              <div class="Editable__text">-</div>
            </div>
          </div>
          <div  class="Field--vertical">
            <button accesskey="3" class="button -alt-highlight" (click)="saveFileRefNo()" >
              <op-icon class="op-icon--wrapper" icon-classes="button--icon icon-checkmark"><i aria-hidden="true"
                  class="button--icon icon-checkmark" title=""></i>
              </op-icon><span class="button--text">{{i18n.t("js.button_save")}}</span>
            </button>
          </div>
        </div>
        <div *ngIf="!saveFileFlag">
          <hr class="B360Hr">
          <h3 style="padding: 5px 10px;" class="attributes-group--header-text">{{i18n.t("js.file-ref-no.title")}}</h3>
          <div class="Field--vertical">
            <label class=" Field__label" *ngIf="data.check_list.file_ref_no">{{ data.check_list.file_ref_no }}</label>
          </div>
        </div>
      </div>
    <hr class="B360Hr">
    <div class="ChecklistPageRightPanel__subtitle" >{{i18n.t("js.check-lists.quest.Details.Createdby")}} {{data.check_list.created_by_user}}，{{detailsbottomtext}}</div>
  </div>
  <div class="editContent" *ngIf="showEditDiv">
    <div class="mainContent">
      <span class="closeIcon" (click)="closeBtn()"></span>
      <div class="imgContent">
        <div class="imgLine">
          <img  [ngClass]="{'imgLeftHeight':(imageLeftData.height > imageLeftData.width),imgLeft: (imageLeftData.height <= imageLeftData.width)}"  [src]="imageLeftData.src"/>
        </div>
        <div class="rightFont" >
          <p class="fontLine">
            <b>{{i18n.t("js.check-lists.list-title.status")}}:</b>
            <select  *ngIf="showEditDiv&&showimgEdit" [(ngModel)]="imageData.status" (change)="goEdit($event)">
              <option value="before">before</option>
              <option value="after">after</option>
            </select>
            <span *ngIf="showEditDiv&&!showimgEdit">{{imageData.status}}</span>
          </p>
          <p class="fontLine" *ngIf="imageData.status=='before'">
            <b>{{i18n.t("js.check-lists.quest.Details.Location")}}:</b>
            <span *ngIf="showEditDiv&&!showimgEdit">{{imageData.location}}</span>
            <input type="text" *ngIf="showEditDiv&&showimgEdit" [(ngModel)]="imageData.location">
          </p>
          <p class="fontLine" *ngIf="imageData.status=='before'">
            <b>GPS</b>
            <span>{{ i18n.t("js.check-lists.quest.Longitude") }}: -</span>
            <span>{{i18n.t("js.check-lists.quest.Latitude")}}: -</span>
          </p>
          <p class="fontLine" *ngIf="imageData.status=='before'">
            <b>Finding:</b>
            <span *ngIf="showEditDiv&&!showimgEdit">{{imageData.finding}}</span>
            <input type="text"  *ngIf="showEditDiv&&showimgEdit"  [(ngModel)]="imageData.finding">
          </p>
          <p class="fontLine" *ngIf="imageData.status=='after'">
            <b>Following Action Taken:</b>
            <span *ngIf="showEditDiv&&!showimgEdit">{{imageData.following_action_token}}</span>
            <input type="text" *ngIf="showEditDiv&&showimgEdit" [(ngModel)]="imageData.following_action_token">
          </p>
        </div>
      </div>
      <div class="bottomBtn" *ngIf="((data.check_list.created_by_id ==userId && (data.check_list.state == 'New' || data.check_list.state.includes('Completed') || data.check_list.state.includes('Contractor Recheck') ||data.check_list.state == 'Consultant Sent' )) || ((data.check_list.assigned_to_id == userId || data.check_list.executor_id == userId)&& data.check_list.state == 'Sent'))">
        <button class="editbtn deletebtn" (click)="goDelete()">{{i18n.t("js.check-lists.template.delete")}}</button>
        <button class="editbtn" (click)="showimgEdit=true" *ngIf="showEditDiv&&!showimgEdit">{{i18n.t("js.documents.edit")}}</button>
        <button class="editbtn confirmbtn" (click)="confirmEdit()" *ngIf="showEditDiv&&showimgEdit">{{i18n.t("js.documents.save")}}</button>
      </div>
    </div>
  </div>
</div>
<div class="wage" *ngIf="assigntLoading"></div>
<div class="SectionItem__loading loadingDiv" *ngIf="showLoading || assigntLoading">
  <div class="loading-indicator -compact">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
</div> 


<!-- pdf -->
<check_lists-pdf  *ngIf="!showPdfdownload" [saveRes]="saveRes" [jumpUrl]="jumpUrl"  (backstep)="backstep()"></check_lists-pdf>

<div class="show_banner" *ngIf="show_banner">
  <div class="banner">
    <span style="margin-top: 20px;">{{i18n.t("js.check-lists.quest.Allpartscomplete")}}</span>
    <div (click)="okTable()">
      <span>{{i18n.t("js.check-lists.quest.Completechecklist")}}</span>
    </div>
  </div>
</div>

<check_lists-autograph *ngIf="signShow" (closeView)="colseSign($event)"></check_lists-autograph>


<check_lists-issue (close)='issueclone()' *ngIf='issueblock' [payload]='payload' [schema]='schema' [userdata]='userData' [byuserdata]='byuserdata'></check_lists-issue>


