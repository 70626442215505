import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  //请求头
  requestHeader: any = {
    'X-Authentication-Scheme': 'Session',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache'
  };
  //API前缀
  apiPrefix: any = '/api/v3';
  constructor(private http: HttpClient) {
    
  }
  projectId :any = document.getElementsByName('current_project')[0].dataset.projectId
  templateCopy: EventEmitter<any> = new EventEmitter<any>();
  
  uploadFile({ p_id, c_id ,file }: any) {
    return this.http.post(
      `${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}/signs`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
//上传图片
  update_img({ p_id, c_id ,file }: any){
    return this.http.post(
      `${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}/images`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }

  update_PDF({ p_id, c_id ,file }: any){
    return this.http.post(
      `${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}/pdf`,
      file,
      {
        withCredentials: true,
        headers: this.requestHeader
      }
    );
  }
  setRecheck(id:any, recheck:any) {
    return this.http.put(`${ this.apiPrefix }/projects/${this.projectId}/check_lists/${id}/update_recheck?recheck=recheck`, {
      project_id:this.projectId,
      check_list_id: id,
      recheck: recheck
    }, {
      withCredentials: true,
      headers: this.requestHeader
    })
  }
  //用户角色
  getUserList(id: any, role:any) {
    return this.http.get(`${ this.apiPrefix }/projects/${ id }/available_responsibles?role=${role}`);
  }
  //当前登录用户
  getbyUserList(id: any, role?:any) {
    return this.http.get(`${ this.apiPrefix }/projects/${ id }/available_assignees?role=${role}`);
  }
   //取消事件冒泡
   stopPropagation(event: any) {
    if (event && event.stopPropagation) {
      event.stopPropagation(); 
    } else {
      window.event && (window.event.cancelBubble = true);
    }
  }
  checkListsTemplate(proid:any,data:any){
    return this.http.post(`${ this.apiPrefix }/projects/${ proid }/check_list_templates`,data,{withCredentials: true,headers: this.requestHeader});
  }
  updatecheckListsTemplate(proid:any,checklistsId:any,data:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ proid }/check_list_templates/${checklistsId}`,data,{withCredentials: true,headers: this.requestHeader});
  }

  //获取位置
  getLocations(kind_id:any){
    return this.http.get(`${ this.apiPrefix }/check_list_locations?check_list_kind_id=${kind_id}`);
  }
  getDetailLocations(id:any){
    return this.http.get(`${ this.apiPrefix }/check_list_locations/${id}`);
  }
  getAssignUser(assign_id:any){
    return this.http.get(`${this.apiPrefix}/users/${assign_id}`)
  }

  //重新提交 file ref no
  resubmit(data:any){
    return this.http.post(`${ this.apiPrefix }/file_ref_no/resubmit`,data,{withCredentials: true,headers: this.requestHeader});
  }

  //file ref no创建前的选择数据
  fileRefNo(project_id:string,type:string){
    return this.http.get(`${ this.apiPrefix }/file_ref_no/create_form_data?project_id=${project_id}&target_type=${type}`);
  }

  //file ref no 保存后的数据
  fileRefNoInfo(obj_id:string,obj_type:string){
    return this.http.get(`${ this.apiPrefix }/file_ref_no/view_form_data?obj_id=${obj_id}&obj_type=${obj_type}`);
  }
  //赋值checklist
  copyChecklist(project_id:any,id:any){
    return this.http.post(`${ this.apiPrefix }/projects/${project_id}/check_lists/${id}/copy`,{check_list_id:id},{withCredentials: true,headers: this.requestHeader});
  }
  saveChecklist(id:any, data:any) {
    return this.http.put(`${ this.apiPrefix }/projects/${this.projectId}/check_lists/${id}`,data,{withCredentials: true,headers: {...this.requestHeader,"Content-Type": "application/json"}});
  }
  //提交 file ref no 数据
  saveFileRefNo(data:any){
    return this.http.post(`${ this.apiPrefix }/file_ref_no/update_file_ref_no`,data,{withCredentials: true,headers: this.requestHeader});
  }

  public postSign(file:any){
    return this.http.post(`/api/v3/projects/${this.projectId}/sign`, file, {withCredentials: true,headers: this.requestHeader})
  }

  getCheckListTypes(kind_id:any){
    return this.http.get(`${ this.apiPrefix }/check_list_types?check_list_kind_id=${kind_id}`);
  }

  getCheckList(p_id:any,c_id:any){
    return this.http.get(`${ this.apiPrefix }/projects/${p_id}/check_lists/${c_id}`);
  }

  getnewtemplate(project_id:any,kind_id:any){
    return this.http.get(`${ this.apiPrefix }/check_list_templates?project_id=${project_id}&check_list_kind_id=${kind_id}`);
  }
  getnewcheckliststemplate(checkListsTemplateID:any){
    return this.http.get(`${ this.apiPrefix }/check_list_templates/${checkListsTemplateID}`);
  }

  getProjectlist(){
    return this.http.get('projects/level_list.json') 
  }

  copyCheckListTemplate(checkListTemplateId:any, porjectId: any) {
    return this.http.post(`${this.apiPrefix}/check_list_templates/${checkListTemplateId}/copy`, { project_id:  Number(porjectId)}, {headers: {'Content-Type': 'application/hal+json',
    'X-Requested-With': 'XMLHttpRequest'
                            }})
  }

  newcheckLists(proid:any,data:any){
    return this.http.post(`${ this.apiPrefix }/projects/${ proid }/check_lists `,data,{withCredentials: true,headers: this.requestHeader});
  }

  checklistsindex(proid:any,data:any,unique_date_sort:any= 'desc'){
    return this.http.get(`${ this.apiPrefix }/projects/${ proid }/check_lists?check_list_kind_id=${data.kind_id}&page=${data.page}&per=${data.per}&unique_date_sort=${unique_date_sort}&by_action=${data.by_action}`);
  }

    // //删除模板
    deleteModulelist(project_id:any,template_id:any){
      let reqheader = this.requestHeader
        reqheader[ 'Content-Type'] = 'application/json'
        console.log(reqheader)
        return this.http.delete(`${this.apiPrefix}/projects/${project_id}/check_list_templates/${template_id}`,{withCredentials: true,headers: reqheader})
      }

  updateItem({p_id,c_id,data}:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}/update_item`,data,{withCredentials: true,headers: this.requestHeader});
  }

  updateCheckList({p_id,c_id,data}:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}`,data,{withCredentials: true,headers: this.requestHeader});
  }

  update_sign(id:any,data:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ this.projectId }/check_lists/${id}/update_sign`,data,{withCredentials: true,headers: this.requestHeader});
  }

  update_part_state({p_id,c_id,data}:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}/update_part_state`,data,{withCredentials: true,headers: this.requestHeader});
  }

  updateCheckListstate({p_id,c_id,data}:any){
    return this.http.put(`${ this.apiPrefix }/projects/${ p_id }/check_lists/${c_id}`,data,{withCredentials: true,headers: this.requestHeader});
  }

  work_packages(id:any,data:any){
    return this.http.post(`${ this.apiPrefix }/projects/${ id }/work_packages/form`,data,{withCredentials: true,headers: this.requestHeader});
  }

  newTaskeissus(data:any){
    return this.http.post(`${ this.apiPrefix }/work_packages/`,data,{withCredentials: true,headers: this.requestHeader});
    // let overview = this.http.get(`${ this.apiPrefix }/projects/overview`);
    // overview.subscribe((data:any)=>{
    //   console.log(data);


    //   let types = this.http.get(`${ this.apiPrefix }/types`);
    //   types.subscribe((res:any)=>{
    //     console.log(res,'?????a');



    //       // let work_packages = this.http.post(`${ this.apiPrefix }/work_packages/`,
    //       // {
    //       //   description: {raw: ""},
    //       //   dueDate: null,
    //       //   estimatedTime: null,
    //       //   percentageDone: 0,
    //       //   startDate: "2020-06-12",
    //       //   subject: "newtask",
    //       //   _links:{
    //       //     assignee:{href: null},
    //       //     attachments:[],
    //       //     category: {href: null},
    //       //     parent: {uncacheable: true, href: null, title: null},
    //       //     priority: workpageres._embedded.schema.priority._links.allowedValues[1],
    //       //     project: {href:`/api/v3/projects/${data.data[0].id}`,title:data.data[0].name},
    //       //     responsible: {href: null},
    //       //     self: {href: null},
    //       //     status: workpageres._embedded.schema.status._links.allowedValues[0],
    //       //     type:  workpageres._embedded.schema.type._links.allowedValues[2],
    //       //     version: {href: null},
    //       //   }
    //       // }
    //       // ,{withCredentials: true,headers: this.requestHeader});

    //       // work_packages.subscribe(res=>{
    //       //   console.log(res);
            
    //       // })
        
    //     })
        
    //   })
      
    
  }



  //新建文件夹
  // createFolder({ id, folder_name }: any) {
  //   return this.http.post(
  //     `${ this.apiPrefix }/folder/${ id }/create`,
  //     { folder_name },
  //     {
  //       withCredentials: true,
  //       headers: this.requestHeader
  //     }
  //   )
  // }
}