<div class="editMainContent">
    <div class="titlediv">
        <div class="leftStatus">
            <b>{{title}}</b>
            <span *ngIf="saveEditContent">{{changeStatus(saveEditContent.state)}}</span>
            <span *ngIf="!saveEditContent">NEW</span>
        </div>
        <div class="rightBtn">
            <button class="frist" (click)="updateFSiteDary()">Update from Site Diary</button>
            <button class="greenbtn right save" (click)="submitLabour('save')">{{ i18n.t("js.labour-return.save") }}</button>
<!--            <button class="greenbtn cancel" (click)="cancelEdit()">{{ i18n.t("js.labour-return.cancel") }}</button>-->
        </div>
    </div>
    <hr class="bighr">
    <div class="detailDate">
     <div class="linediv">
         
        <p class="linep"><b>Department/Division:</b><input type="text" class="littleinput" [(ngModel)]="labour_return.department"><span style="margin: 0 10px;">/</span><input type="text" class="littleinput" [(ngModel)]="labour_return.division"></p>
        <p class="linep"><b>Month/Year:</b><span>{{changeTitle(title)}}</span></p>
     </div>
     <div class="linediv">
        <p class="linep"><b>Contract No.:</b><span><input type="text" class="littleinput" [(ngModel)]="labour_return.contract_no"></span></p>
        <p class="linep"><b>Contract Title:</b><span><input type="text" class="littleinput" [(ngModel)]="labour_return.contract_title"></span></p>
     </div>
     <div class="linediv">
        <p class="linep"><b>Contractor:</b><span><input type="text" class="littleinput" [(ngModel)]="labour_return.contractor"></span></p>
        <p class="linep"><b>Works Code:</b><span><input type="text" class="littleinput" [(ngModel)]="labour_return.works_code"></span></p>
     </div>
    </div>
    <hr class="bighr">
    <p class="inputwidth" *ngIf="saveEditContent&&saveEditContent.state=='rejected'"><b>{{ i18n.t("js.labour-return.rejected") }}:</b><textarea rows="4" cols="50" disabled [(ngModel)]="saveEditContent.reject_remark"></textarea></p>
    <button class="sumBtn" (click)="getSum()">{{ i18n.t("js.labour-return.sum") }}</button>
    <div class="tableContent">
        <labour_return-table [bgcolor]="'#fffffff'"  [numArray]="numArray" [clomnArray]="clomnArray"></labour_return-table>
    </div>
</div>
