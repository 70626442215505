/*
 * @Author: your name
 * @Date: 2020-05-28 16:44:44
 * @LastEditTime: 2020-06-05 15:57:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /junhe/frontend/src/app/modules/check_lists/components/check_lists-list/check_lists-list.component.ts
 */ 
import { Component, OnInit ,Input,Output,EventEmitter, NgZone} from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { DocumentsService } from '../../services/check.lists.service';

declare let layui: any
@Component({
  selector: 'check_lists-list',
  templateUrl: './check_lists-list.component.html',
  styleUrls: ['./check_lists-list.component.sass']
})
export class Check_listsListComponent implements OnInit {

  @Input() public checklistsindexitem:any
  @Input() public kind_id:any = 1
  @Output() clicklistid = new EventEmitter();
  @Output() sortType = new EventEmitter();
  //右键菜单X轴
  public itemX: string;
  //右键菜单Y轴
  public itemY: string;
  //右键菜单id
  public rightID: string;
  //右键菜单的状态
  public rightState:string;
  public checklistArr = ['', 'Checklist', 'Safety', 'Cleansing', "Weekly Environmental Walk Inspection", "Weekly Safety Walk Inspection","Monthly Safety", "Weekly Safety Walk Inspection New"]
  public unique_date_sort:string = 'desc'
   // fileRefNo type
  public fileRefNoType:string;
  public check_listsID:any;
  public prefixType:any;
  public isUserAdmin:boolean = false
  public userList = []
  public project_id:any
  constructor(
    public i18n: I18nService,
    private zone: NgZone,
    public service: DocumentsService
  ) { }
  ngOnInit() {
    const el =  document.querySelector('meta[name=current_project]')
    // @ts-ignore
    this.project_id = el.dataset.projectIdentifier
    this.isUserAdmin = sessionStorage.getItem('isUserAdmin') === 'true' ? true : false
    jQuery('#content-wrapper').css({'background':'#ffffff','padding': "15px"});
    jQuery(document).bind('click', function(e:any) {
      var e = e || window.event; //浏览器兼容性 
      var elem:any = e.target || e.srcElement;
      while (elem) { //循环判断至跟节点，防止点击的是div子元素 
          if (elem.id && elem.id == 'checkListsMenu') {
              return;
          }
          elem = elem.parentNode;
      }
      jQuery('#checkListsMenu').css('visibility', 'hidden'); //点击的不是div或其子元素 
    });
    this.getUserList()
  }

  /**
   * @author shane
   * @description:获取用户列表
   * @date 2023--12--08 15 21 16
   */
  getUserList(){
    fetch(`api/v3/projects/${this.project_id}/check_lists/users`)
      .then(response => response.json())
      .then(data => {
        this.userList = data.map((item:any) => {
          item.title = item.name
          return item
        })
      })
      .catch(error => console.error(error))
  }

  checkKindId(){
    const arr = [8,9,10]
    return !arr.includes(Number(this.kind_id))
  }
  /**
   * @author shane
   * @description:变更操作人
   * @date 2023--12--07 14 55 47
  */
  changeUser(userItem:any,event:any){
    let urlItem = userItem.id
    let parm = {
      assigned_to_last_id:'',
      assigned_to_id:'',
      state:'',
      recheck:''
    }
    if(!!userItem.assigned_to_last_id){
      parm.assigned_to_last_id = ''
      delete parm.assigned_to_id
      parm.state = userItem.state
    }else{
      parm.assigned_to_id = ''
      delete parm.assigned_to_last_id
      parm.state = userItem.state
    }
    let self  = this
    layui.use(['layer','dropdown'], function() {
      var layer = layui.layer,dropdown = layui.dropdown
      layer.open({
        content:`<button class="layui-btn layui-btn-primary userList">
                     Select Handler
                    <i class="layui-icon layui-icon-down layui-font-12"></i>
                </button>
       `,
        // area: ['400px', '400px'],
        title:'Change Handler',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          layer.confirm('ConFirm to change the Handler？', {
            title:'Alert',
            btn: ['Yes','Cancel'] //按钮
          }, function(){
            fetch(`/api/v3/projects/${self.project_id}/check_lists/`+ urlItem, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'X-Authentication-Scheme':'Session',
                'X-Requested-With': 'XMLHttpRequest',
                'X-Csrf-Token': jQuery("meta[name=csrf-token]").attr("content")||''
              },
              body: JSON.stringify(parm)
            })
              .then(response => response.json())
              .then(data => {
                self.sortType.emit(self.unique_date_sort)
                layer.msg('Update Success', {icon: 1})
              })
              .catch(error => console.error(error))
          }, function(){
          });
          //do something
          layer.close(index);

        }
      })
      dropdown.render({
          elem: '.userList',
          className:'dropdownClass',
          style:'height: 300px; overflow-y: auto; overflow-x: hidden'
         ,data: self.userList
        ,click: function(obj:any){
          this.elem.text(obj.title);
          if(parm.hasOwnProperty('assigned_to_last_id')){
            parm.assigned_to_last_id = obj.id
          }else{
            parm.assigned_to_id = obj.id
          }
        }
      })
    })
    event.stopPropagation()
  }

  changeStatus(item:any,e:any){
    let parm = {
      state:''
    }
    let self  = this
    layui.use(['layer','dropdown'], function() {
      var layer = layui.layer,dropdown = layui.dropdown
      layer.open({
        content:`<button class="layui-btn layui-btn-primary statusList">
                     Select Status
                    <i class="layui-icon layui-icon-down layui-font-12"></i>
                </button>
       `,
        // area: ['400px', '400px'],
        title:'Change Status',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          layer.confirm('ConFirm to change the Status？', {
            title:'Alert',
            btn: ['Yes','Cancel'] //按钮
          }, function(){
            fetch(`/api/v3/projects/${self.project_id}/check_lists/`+ item+'/update_state', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'X-Authentication-Scheme':'Session',
                'X-Requested-With': 'XMLHttpRequest',
                'X-Csrf-Token': jQuery("meta[name=csrf-token]").attr("content")||''
              },
              body: JSON.stringify(parm)
            })
              .then(response => response.json())
              .then(data => {
                self.sortType.emit(self.unique_date_sort)
                layer.msg('Update Success', {icon: 1})
              })
              .catch(error => console.error(error))
          }, function(){
          });
          //do something
          layer.close(index);
        }
      })

      dropdown.render({
        elem: '.statusList',
        className:'dropdownClass',
        // style:'height: 300px; overflow-y: auto; overflow-x: hidden',
        data: [
          {
            title:'Cancel',
            value:'Cancel'
          },
          {
            title:'New',
            value:'New'
          }
        ]
        ,click: function(obj:any){
          this.elem.text(obj.title);
          parm.state = obj.value
        }
      })
    })
    e.stopPropagation()
  }
  clicklistitem(even:any,e:any){
    e.stopPropagation();
    this.clicklistid.emit(even.id)
  }
  getStringValue(value:any) {
    return JSON.stringify(value)
  }
  copyList(even:any,e:any){
    e.stopPropagation();
    let projectId = jQuery('meta[name=current_project]').data('projectId')
    this.service.copyChecklist(projectId,even.id).subscribe((res:any)=>{
      this.check_listsID = res.check_list.id
      this.kind_id = location.search.split('?')[1].split('&')[0].split('=')[1]
      const type = this.checklistArr[this.kind_id];
      this.service.fileRefNo(res.check_list.project_id,type).subscribe((ress:any) => {
        this.prefixType =ress.data.prefix
        console.log(ress.data.prefix);
        this.zone.run(() => {
            if (ress.data.values.length > 0) {
              this.fileRefNoType = this.getStringValue(ress.data.values[0].value) || "";
              this.saveFileRefNo()
            }
        });
      });
    })
  }
  public saveFileRefNo() {
    const obj_type: string = 'CheckList' ;
    let fileRefNoType = JSON.parse(this.fileRefNoType)
    this.service
      .saveFileRefNo({
        obj_id: this.check_listsID,
        obj_type,
        prefix: this.prefixType,
        type_value: fileRefNoType,
      })
      .subscribe((res:any) => {
        this.clicklistid.emit('copy')   
      });
    
    }
  changeSort() {
    this.unique_date_sort == 'asc'?this.unique_date_sort = 'desc':this.unique_date_sort = 'asc'
    this.sortType.emit(this.unique_date_sort)
  }
  getMonthEn(number: any) {
    let Enlist ={
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sept',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec'
    } as any
    return Enlist[number]
  }
  //偏移位置计算
  private mousePosition(ev: any) {
    if (ev.pageX || ev.pageY) {
      return {
        x: ev.pageX,
        y: ev.pageY,
      };
    }
    return {
      x: ev.clientX + document.body.scrollLeft - document.body.clientLeft - 90,
      y: ev.clientY + document.body.scrollTop - document.body.clientTop - 30,
    };
  }
  public onRightClick(e: any, id: string, state:string) {
    e.stopPropagation(); //阻止冒泡
    e.preventDefault(); // 阻止默认行为
    var mousePos = this.mousePosition(e);
    this.itemX = mousePos.x;
    this.itemY = mousePos.y;
    this.rightID = id;
    this.rightState = state
    jQuery('#checkListsMenu').css('visibility', 'visible'); //点击的不是div或其子元素 
  }
  public Cancel() {

    this.service.saveChecklist(Number(this.rightID), {
      state:"Cancel"
    }).subscribe((res:any) => {
      console.log(res)
      if (res=="200") {
        window.location.href = window.location.href;
      } 
    })
  }
  //重新提交
  public resubmit() {
    if(this.rightID){
      this.service
      .resubmit({
        obj_id:this.rightID,
        obj_type: "Checklist",
      })
      .subscribe((res: any) => {
        console.log("resubmit", res);
        if (res.result == "ok") {
          window.location.href = window.location.href;
        } else {
          alert(res.reason);
        }
      });
    }
  }
}
